
html {
    font-size: 2vh;
}


/* scale based on width for narrow screens */
@media (max-aspect-ratio: 3/4) {
    html {
        /* 2.66 == 2 * 4/3 -- this smooths resizing across the breakpoint */
        /* (at the breakpoint, 1vh == 4/3 vw, so 2vh == 2 * 4/3 vw == 2.66vw) */
        font-size: 2.66vw;
    }
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;

    user-select: none;
}
* {
    box-sizing: border-box;
    margin: 0;
}

.clickable-looking {
    cursor: pointer;
}

/* not hidden */
* {
    transition: visibility 0s 0s;
}
.hidden {
    opacity: 0;
    pointer-events: none;
}

.fade-in-out {
    transition: opacity 1s,
                visibility 0s 1s;
}


#title-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 20;
}


#game-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
}


#console-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    z-index: 10;
}

#console {
    background: url("/images/mediumcherry.png");
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    color: white;
    font-family: Helvetica, sans-serif;
}

#console.review-mode {
    background: url("/images/darkcherry.png");
}
#console.review-mode .board-border {
    background: black;
}
#console.review-mode #done-reviewing-button ~ div {
    display: none;
}
#done-reviewing-button {
    display: inline-block;
    margin-left: 2rem;
}
#done-reviewing-button.hidden {
    display: none;
}



#top-bar-area {
    margin: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

#top-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.top-bar-item {
    margin-left: 1rem;
    margin-right: 1rem;
}

#top-bar .button {
    width: 7em;
    text-align: center;
}

.display {
    text-align: center;
}


#big-text-display {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    font-size: 25vh;
    color: white;
    text-shadow: 0.3rem 0.3rem 0.3rem rgba(0,0,0, 0.4);
    pointer-events: none;
}


#board-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#board {
    position: relative;
}

.board-border { background: url("/images/darkcherry.png"); }

.board-square.black { background: url("/images/lightcherry.png"); }
.board-square.white { background: url("/images/pine.png"); }


#game-end-screen {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
}

